.hobbies__container {
  grid-template-columns: repeat(3, 270px);
  justify-content: center;
  column-gap: 1.8rem;
}

.hobbies__content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: var(--container-color);
  padding: 2rem;
}

.hobbies__icon {
  display: block;
  font-size: 4rem;
  text-align: center;
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.hobbies__title {
  font-size: var(--h4-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
  text-align: center;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .hobbies__container {
        grid-template-columns: repeat(3, 218px);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .hobbies__container {
        grid-template-columns: repeat(2, 1fr);
    }

    .hobbies__content {
        padding: 1.25rem
    }

    .hobbies__icon {
        font-size: 2.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .hobbies__container {
        grid-template-columns: max-content;
    }

    .hobbies__content {
        padding: 1rem 4rem;
    }    
}
